// 停用或啟用角色审批流 http://192.168.0.12:8765/doc.html#/haolv-consumer/t-us-role-controller/updateStatusUsingPOST_2

const __request = require(`./__request/__request_contentType_json`);
const consumer_role_updateStatus = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/role/updateStatus',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_role_updateStatus;