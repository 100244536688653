import Q from "q";
// 角色列表
import consumer_role_list from "@/lib/data-service/haolv-default/consumer_role_list.js";
// 删除角色
import consumer_role_delete from "@/lib/data-service/haolv-default/consumer_role_delete.js";
//  请求员工数据
import consumer_department_getDepartmentUsers from "@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers";
// 添加修改角色
import consumer_role_addOrUpdate from "@/lib/data-service/haolv-default/consumer_role_addOrUpdate.js";
// 批量绑定角色
import consumer_userRole_batchBinding from "@/lib/data-service/haolv-default/consumer_userRole_batchBinding.js";
// 停用或啟用角色
import consumer_role_updateStatus from "@/lib/data-service/haolv-default/consumer_role_updateStatus.js";
// 员工组件
import Transfer from "@/component/transfer/1.0.1/index.vue";

export default {
  components: {
    Transfer,
  },
  data() {
    var validatorIds = (rule, value, callback) => {
      if (value === '' || value === undefined || value === null || !value.length) {
        callback(new Error('请选择员工'));
      } else {
        callback();
      }
    }

    return {
      loading: false,
      roleVisible: false,
      roleTitle: "",
      form: {
        currentPage: 1,
        pageSize: 10,
        roleName: "",
        roleSource: "1",
      },
      pager: {
        pageSizes: [10, 15, 20, 25, 30],
        total: 0,
      },
      list: [],
      roleForm: {
        roleName: "",
        ids: [],
        id: "",
        roleSource: "1",
      },
      roleRules: {
        roleName: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
        // 非必选
        ids: [{ required: true, validator: validatorIds, trigger: "blur" }],
      },
      treeData: [],
    };
  },
  methods: {
    req_list(params) {
      const _this = this;
      _this.loading = true;
      _this.list = [];
      Q.when()
        .then(function() {
          return consumer_role_list(params);
        })
        .then(function(res) {
          _this.list = res.datas.list;
          _this.pager.total = res.datas.totalCount;
          _this.loading = false;
        });
    },
    req_staff(params) {
      return Q.when().then(function() {
        return consumer_department_getDepartmentUsers(params);
      });
    },
    req_add(params) {
      return Q.when().then(function() {
        return consumer_role_addOrUpdate(params);
      });
    },
    req_bind_role(params) {
      return Q.when().then(function() {
        return consumer_userRole_batchBinding(params);
      });
    },
    req_update(params) {
      console.log(params);
      const _this = this;
      Q.when()
        .then(function() {
          return consumer_role_updateStatus(params);
        })
        .then(function(res) {
          if (params.roleStatus === 1) {
            _this.$message.success("启用成功");
          } else if (params.roleStatus === 2) {
            _this.$message.success("停用成功");
          } else if (params.roleStatus === 3) {
            _this.$message.success("删除成功");
          }
          _this.req_list(_this.form);
        });
    },
    req_delete(params) {
      const _this = this;
      Q.when()
        .then(function() {
          return consumer_role_delete(params);
        })
        .then(function(res) {
          _this.$message.success("删除成功");
          _this.req_list(_this.form);
        });
    },
    search() {
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    change_search(val) {
      if (!val) {
        this.search();
      }
    },
    add_role(type, row) {
      const _this = this;
      if (type === "add") {
        _this.roleTitle = "新增角色";
      } else if (type === "change") {
        _this.roleTitle = "编辑角色";
        _this.roleForm.id = row.id;
        _this.roleForm.roleName = row.roleName;
        _this.roleForm.ids = row.userIds || [];
      }
      _this.roleVisible = true;
    },

    getReqStaff() {
      const _this = this;
      _this.roleLoading = true;
      Q.when()
          .then(function() {
            return _this.req_staff();
          })
          .then(function(res) {

            const list = res.datas;
            let formatData = (datas) => {
              const list = datas.map((data) => {
                let obj = {};
                obj.deptId = data.deptId;
                obj.deptName = data.deptName;
                obj.children = [];
                obj.userList = [];
                if (data.deptList.length) {
                  obj.children = formatData(data.deptList);
                }
                if (data.staffList.length) {
                  obj.userList = data.staffList.map((staff) => {
                    return { id: staff.subUserId, name: staff.subStaffName };
                  });
                }
                return obj;
              });
              return list;
            };

            _this.treeData = formatData(list);
            _this.roleLoading = false;


          });
    },
    close_roleDialog() {
      this.roleVisible = false;
      this.$refs.roleForm.resetFields();
    },
    commit_role() {
      const _this = this;
      const form = _this.roleForm;
      this.$refs.roleForm.validate((valid) => {
        if (valid) {
          Q.when()
            .then(function() {
              return _this.req_add({
                id: form.id,
                roleName: form.roleName,
                roleForm: form.roleForm,
                roleSource: form.roleSource,
              });
            })
            .then(function(res) {
              let message = "";
              Q.when()
                .then(function() {
                  if (_this.roleTitle === "新增角色") {
                    message = "添加成功";
                    return _this.req_bind_role({
                      roleId: res.datas,
                      userIds: form.ids,
                    });
                  } else if (_this.roleTitle === "编辑角色") {
                    message = "修改成功";
                    return _this.req_bind_role({
                      roleId: form.id,
                      userIds: form.ids,
                    });
                  }
                })
                .then(function(res) {
                  _this.close_roleDialog();
                  _this.$message.success(message);
                  _this.form.currentPage = 1;
                  _this.req_list(_this.form);
                });
            });
        } else {
          return false;
        }
      });
    },
    offline_role(roleStatus, id) {
      const message =
        roleStatus === 1
          ? "启用"
          : roleStatus === 2
          ? "停用"
          : roleStatus === 3
          ? "删除"
          : "";
      this.$confirm(`是否确定${message}此角色`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.req_update({ roleStatus, id });
        })
        .catch(() => {});
    },
    delete_role(params) {
      this.$confirm("是否确定删除此角色", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.req_delete(params);
        })
        .catch(() => {});
    },
    change_pageSize(pageSize) {
      this.form.pageSize = pageSize;
      this.form.currentPage = 1;
      this.req_list(this.form);
    },
    change_currentPage(currentPage) {
      this.form.currentPage = currentPage;
      this.req_list(this.form);
    },
  },
  mounted() {
    this.getReqStaff();
    this.req_list(this.form);
  },
  activated() {},
  deactivated() {},
};
