export default {
    name: 'transfer',
    data() {
        return {
            searchTransferText: '',
            transferList: [],
            activeList: [],
            active: this.value
        }
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        value: {
            type: Array,
            default: () => []
        },
    },
    components: {
        transferTree: () => import('./transfer-tree/index.vue')
    },
    created() { },
    mounted() {
        let params = this.initTreeData(this.list)
        this.transferList = params.list
        this.activeList = params.activeList
        this.transferList = this.filterList(this.transferList, 'name', this.searchTransferText)
    },
    activated() {
    },
    deactivated() { },
    destroyed() { },
    watch: {
        /*value: {
            immediate:true,
            handler(newVal) {
                this.active = newVal
            }
        },*/
        value(newVal) {
            this.active = newVal
        },
        /*active: {
            immediate:true,
            handler(newVal) {
                this.$emit('input', newVal)
                this.activeToGetArr(newVal)
            }
        },*/
        active(newVal) {
            this.$emit('input', newVal)
            this.activeToGetArr(newVal)
        },
        searchTransferText(newVal) {
            this.transferList = this.filterList(this.transferList, 'name', newVal)
        },
        list(newVal) {
            let params = this.initTreeData(newVal)
            this.transferList = params.list
            this.activeList = params.activeList
            this.transferList = this.filterList(this.transferList, 'name', this.searchTransferText)
        },
        transferList: {
            handler (val) {
                
                this.$nextTick(() => {
                    this.toSetActive();
                });
                
            },
            deep: true,
        },
    },
    computed: {
    },
    filters: {},
    methods: {
        initTreeData(list) {
            let activeList = []
            let reduceDataFunc = (data, level) => {
                data.map((m, i) => {
                    m.isExpand = false // 是否展开
                    // m.check = false
                    // m.children = m.children || []
                    m.level = level
                    m.bLeft = level === 1 ? 0 : (level - 1) * 15
                    if (m.userList && m.userList.length > 0) {
                        m.userList.forEach(value => {
                            value.check = false
                            value.canCheck = true
                            // value.userId = value.subUserId
                            // value.userName = value.subUserName
                            if (this.active.indexOf(value.id) > -1) {
                                value.disabled = true
                                activeList.push(value)
                            } else {
                                value.disabled = false
                            }
                        })
                    }
                    if (m.children && m.children.length > 0) {
                        reduceDataFunc(m.children, level + 1)
                    }
                })
            }
            reduceDataFunc(list, 1)
            return { list, activeList }
        },
        getActiveData() {

        },
        handlerExpand(val) {
            val.isExpand = !val.isExpand
        },
        // 过滤搜索名字
        filterList(arr, key, keyValue) {
            let newList = JSON.parse(JSON.stringify(arr))
            // 遍历
            function fullCheck(newArr, key, keyValue) {
                newArr.forEach(value => {
                    if (value.userList && value.userList.length > 0) {
                        value.userList.forEach(val => {
                            if (val[key].indexOf(keyValue) > -1) {
                                val.canCheck = true
                            } else {
                                val.canCheck = false
                            }
                        })
                    }
                    if (value.children && value.children.length > 0) {
                        fullCheck(value.children, key, keyValue)
                    }
                })
                return newArr
            }
            newList = fullCheck(newList, key, keyValue)
            return newList
        },
        toSetActive() {
            let activeList = []
            let getActive = function (list) {
                list.forEach(value => {
                    if (value.userList && value.userList.length > 0) {
                        value.userList.forEach(value1 => {
                            if (value1.check && value1.canCheck) {
                                activeList.push(value1)
                                value1.check = false
                                value1.disabled = true
                            }
                        })
                    }
                    if (value.children && value.children.length > 0) {
                        getActive(value.children)
                    }
                })
            }
            getActive(this.transferList)
            let oldActiveList = this.activeList
            this.activeList = oldActiveList.concat(activeList)
            this.$emit('getItem', this.activeList)
            this.arrToGetActive(this.activeList)
        },
        arrToGetActive(arr) {
            let list = []
            arr.forEach(value => {
                list.push(value.id)
            })
            this.active = list
        },
        activeToGetArr(arr) {
            let activeList = []
            let reduceDataFunc = (data, arr) => {
                data.map((m, i) => {
                    if (m.userList && m.userList.length > 0) {
                        m.userList.forEach(value => {
                            if (arr.indexOf(value.id) > -1) {
                                value.disabled = true
                                activeList.push(value)
                            } else {
                                value.disabled = false
                            }
                        })
                    }
                    if (m.children && m.children.length > 0) {
                        reduceDataFunc(m.children, arr)
                    }
                })
            }
            reduceDataFunc(this.transferList, arr)
            this.activeList = activeList
        },
        delActive(val, index) {
            let cancelActive = function (list, val) {
                list.forEach(value => {
                    if (value.userList && value.userList.length > 0) {
                        value.userList.forEach(value1 => {
                            if (value1.id === val.id) {
                                value1.disabled = false
                            }
                        })
                    }
                    if (value.children && value.children.length > 0) {
                        cancelActive(value.children, val)
                    }
                })
            }
            cancelActive(this.transferList, val)
            this.activeList.splice(index, 1)
            this.$emit('getItem', this.activeList)
            this.active.splice(this.active.indexOf(val.id), 1)
        },

        // 废弃
        filterArr(arr, key, keyValue) {
            let newArr = JSON.parse(JSON.stringify(arr))

            // 将多维数组转成一维数组
            function jsonToArray(nodes) {
                let r = [];
                if (Array.isArray(nodes)) {
                    for (let i = 0, l = nodes.length; i < l; i++) {
                        r.push(nodes[i]); // 取每项数据放入一个新数组
                        if (Array.isArray(nodes[i]["children"]) && nodes[i]["children"].length > 0)
                            // 若存在children则递归调用，把数据拼接到新数组中，并且删除该children
                            r = r.concat(jsonToArray(nodes[i]["children"]));
                        delete nodes[i]["children"]
                    }
                }
                return r;
            }
            let aa = jsonToArray(newArr)
            // 筛选
            function filterArray(node, key, keyValue) {
                let newNode = node
                // 初始化一次
                newNode.forEach(value => {
                    value.canCheck = false
                })
                function getParent(parentId) {
                    if (parentId !== undefined || parentId !== 0) {
                        for (let i = 0, l = newNode.length; i < l; i++) {
                            if (newNode[i].id === parentId) {
                                newNode[i].canCheck = true
                                getParent(newNode[i].parentId)
                            }
                        }
                    }
                }
                newNode.forEach(value => {
                    if (value[key].indexOf(keyValue) > -1) {
                        value.canCheck = true
                        getParent(value.parentId)
                    }
                })
                return node
            }
            aa = filterArray(aa, key, keyValue)
            // 组装成树形
            function translateDataToTree(data) {
                const parents = data.filter(value => value.parentId === 0 || value.parentId === undefined || value.parentId === null) // 没有父节点的数据
                const children = data.filter(value => value.parentId !== 0) // 有父节点的数据
                // 定义转换方法的具体实现
                const translator = (parents, children) => {
                    parents.forEach((parent) => {
                        children.forEach((current, index) => {
                            if (current.parentId === parent.id) {
                                const temp = JSON.parse(JSON.stringify(children))
                                temp.splice(index, 1)
                                translator([current], temp)
                                typeof parent.children !== 'undefined' ? parent.children.push(current) : parent.children = [current]
                            }
                        }
                        )
                    }
                    )
                }
                translator(parents, children)
                return parents
            }
            aa = translateDataToTree(aa)
            return aa
        },
    }
}